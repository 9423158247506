import {
  Button,
  List,
  Space,
  Table,
  useTable,
  Icons,
  Typography,
} from '@pankod/refine-antd'
import { useMany, useTranslate, useUpdateMany } from '@pankod/refine-core'
import type { TableRowSelection } from 'antd/lib/table/interface'
import { useState, type Key } from 'react'
import type { HydraId } from 'src/adapters/DataProvider/types'
import { Loading } from 'src/components/Loading'
import { Rate } from 'src/components/Rate'
import { ShowButton, EditButton } from 'src/components/ResourceButtons'
import { useSearch } from 'src/libs/useSearch'
import type { Dispenser, DispenserPosition } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

import { CategoriesField } from './CategoryField'
import { DispenserTagsField } from './DispenserTagField'

import './style.less'

export function ListView() {
  const translate = useTranslate()

  const { queryFilter, listProps } = useSearch()
  const { tableProps } = useTable<Dispenser>({ permanentFilter: queryFilter })

  const [selectedRows, setSelectedRows] = useState<Key[]>([])

  const rowSelection: TableRowSelection<Dispenser> = {
    selectedRowKeys: selectedRows,
    onChange(selectedRowKeys) {
      setSelectedRows(selectedRowKeys)
    },
  }

  const { mutate, isLoading } = useUpdateMany()

  function activateMany() {
    mutate({
      resource: ResourcePathEnum.dispensers,
      ids: selectedRows.map((id) => id.toString()),
      values: { isDisabled: false },
    })
    setSelectedRows([])
  }

  return (
    <List {...listProps} canCreate={false}>
      <div className="AteTableBatchActionsWrapper">
        {selectedRows.length > 0 && (
          <Space className="AteTableBatchActions">
            <Button
              onClick={activateMany}
              icon={<Icons.CheckOutlined />}
              loading={isLoading}
              style={{ margin: 8 }}
            >
              {translate('buttons.activate_selection')}
            </Button>
            <Typography.Text>
              {translate('informations.selected', {
                count: selectedRows.length,
              })}
            </Typography.Text>
          </Space>
        )}
      </div>
      <Table
        {...tableProps}
        rowSelection={rowSelection}
        rowKey="id"
        rowClassName={(dispenser) => {
          if (dispenser.isDisabled || dispenser.isDisabledByClient)
            return 'AteTableRow -disabled'
          return 'AteTableRow'
        }}
      >
        <Table.Column
          dataIndex="code"
          title={translate('dispensers.fields.code')}
        />
        <Table.Column
          dataIndex="name"
          title={translate('dispensers.fields.name')}
        />
        <Table.Column<Dispenser>
          dataIndex="categories"
          title={translate('dispensers.fields.categories')}
          render={(_, dispenser) => {
            const { categories, mainCategory } = dispenser

            return (
              <CategoriesField value={categories} mainCategory={mainCategory} />
            )
          }}
        />
        <Table.Column<Dispenser>
          dataIndex="tags"
          title={translate('dispensers.fields.tags')}
          render={(_, dispenser) => {
            const { tags } = dispenser
            return <DispenserTagsField value={tags} size="small" />
          }}
        />
        <Table.Column<Dispenser>
          dataIndex="positions"
          title={translate('dispensers.fields.postalCodes')}
          render={(_, dispenser) => {
            const { positions } = dispenser
            return <DispenserPositionsField value={positions} />
          }}
        />

        <Table.Column<Dispenser>
          dataIndex="averageRating"
          title={translate('dispensers.fields.averageRating')}
          render={(value, dispenser) => {
            const { nbRatings } = dispenser
            return (
              <Rate
                value={value}
                text={<Typography>{`(${nbRatings})`}</Typography>}
              />
            )
          }}
        />

        <Table.Column<Dispenser>
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton recordItemId={record.id} />
                <EditButton recordItemId={record.id} />
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}

type DispenserPositionsFieldProps = {
  value: HydraId[]
}
function DispenserPositionsField(props: DispenserPositionsFieldProps) {
  const { value } = props
  const { data: positions, isLoading } = useMany<DispenserPosition>({
    resource: ResourcePathEnum.dispenser_positions,
    ids: value,
    queryOptions: {
      enabled: Boolean(value.length),
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  })

  if (isLoading) return <Loading />
  return (
    <Space>
      {new Set(positions?.data.map((position) => position.postalCode))}
    </Space>
  )
}
