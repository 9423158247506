import {
  Space,
  CreateButton,
  Button,
  Icons,
  Typography,
  Skeleton,
  Image,
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { Badge, List } from 'antd'
import { Link } from 'react-router-dom'
import { cleanHydraId } from 'src/adapters/DataProvider'
import { CurrencyField } from 'src/components/Currency'
import { useDocumentUrl } from 'src/libs/useMinioFile'
import type {
  ExtendedDispenser,
  ExtendedItem,
} from 'src/types/api/extendedTypes'
import url from 'src/UI/theme/picto.svg'

const { Paragraph } = Typography

type FieldsProps = {
  dispenser?: ExtendedDispenser
}

export function ProductsField(props: FieldsProps) {
  const { dispenser } = props
  const translate = useTranslate()
  const isEmpty = dispenser?.items && !dispenser?.items.length

  if (isEmpty) {
    return (
      <Space direction="vertical" size={0}>
        <Paragraph italic>
          {translate('dispensers.values.items.empty')}
        </Paragraph>

        {dispenser && (
          <CreateButton
            resourceNameOrRouteName={`/items/create?dispenser=${cleanHydraId(dispenser.id)}`}
            type="primary"
            onClick={undefined}
          >
            {translate('dispensers.actions.createFirstItem')}
          </CreateButton>
        )}
      </Space>
    )
  }

  return (
    <List>
      {dispenser?.items?.map((product) => (
        <ProductField key={product.id} product={product} size="default" />
      ))}
    </List>
  )
}

type ProductFieldProps = {
  product: ExtendedItem
  size: 'small' | 'large' | 'default'
}

function ProductField(props: ProductFieldProps) {
  const { product, size } = props
  const document = product.documents?.[0]?.id
  const file = useDocumentUrl(document)
  const translate = useTranslate()

  const activePromotion = product.activePromotionObject

  const displaySize = {
    small: 32,
    default: 64,
    large: 128,
  }[size]

  const listItem = (
    <List.Item
      key={product.id}
      style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <List.Item.Meta
          avatar={
            <Image
              src={file || url}
              preview={Boolean(file)}
              width={displaySize}
              height={displaySize}
              placeholder={
                <Skeleton.Image
                  style={{ width: displaySize, height: displaySize }}
                />
              }
            />
          }
          title={product.name}
          description={product.description}
          style={{
            display: 'flex',
            alignItems: 'center',
            minWidth: 150,
            flex: 1,
          }}
        />

        <span style={{ marginLeft: 'auto' }}>
          {activePromotion ? (
            <Space direction="vertical" size={0}>
              <CurrencyField value={product.price} delete />
              <CurrencyField value={activePromotion.price} type="warning" />
            </Space>
          ) : (
            <CurrencyField value={product.price} />
          )}
        </span>
      </div>

      <Space wrap>
        <Space>
          <Link to={`/items/edit/${cleanHydraId(product.id)}`}>
            <Button icon={<Icons.EditOutlined />}>
              {translate('buttons.edit')}
            </Button>
          </Link>

          <Link
            to={
              activePromotion
                ? `/promotions/edit/${cleanHydraId(activePromotion.id)}`
                : `/promotions/create?product=${cleanHydraId(product.id)}`
            }
          >
            <Button icon={<Icons.EditOutlined />}>
              {translate('buttons.managePromotion')}
            </Button>
          </Link>
        </Space>
      </Space>
    </List.Item>
  )

  if (product.activePromotion)
    return (
      <Badge.Ribbon
        placement="start"
        text={translate('items.fields.activePromotion')}
      >
        {listItem}
      </Badge.Ribbon>
    )

  return listItem
}
